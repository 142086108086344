









































import { Component, Prop, Vue, Emit, Watch } from "vue-property-decorator";
//@ts-ignore
import { selectUserMenu,} from '@/api/public.js';
function broken(menu: object | undefined): any {
  return menu == undefined ? {} : menu;
}

@Component
export default class MenuContainer extends Vue {
  @Prop() private active_name!: string;
  private mine_active: string = this.active_name;
  private menu_list: Array<any> = [];
  private menu:Array<any> = [];

  @Watch("active_name", { immediate: true, deep: true })
  private onActiveNameChange(newValue: string, oldValue: string) {
    if (this.menu_list.length === 0) return false;
    this.changeMenuStatus(newValue);
  }

  @Emit("active")
  private emitActive(title: string, name: string, crumb: any): object {
    return { title, name, crumb };
  }
  
  private async getMenu(){
    let userInfo=JSON.parse(sessionStorage.getItem('userInfo'))
    let data={data:userInfo.roleId};
    // 将从后台获取到的菜单信息和原本的菜单信息进行对比，然后将原本菜单中多余的去除，再进行渲染
    await selectUserMenu(data).then(res=>{
      if(res.success){
        let list=[];
        this.menu=JSON.parse(res.data);
        this.menu.forEach(element=>{ // 后台获取的该角色的权限菜单
          this.menu_list.forEach(ele=>{ // 本地的所有菜单
            if(element.MenuName == ele.name_cn){ // 等级一的菜单：角色的权限菜单与本地菜单名字相同，则push进list
              list.push({...ele}); // 先将本地等级一的整个菜单push进list
              let where=list.length-1;
              list[where].children=[]; // 再将children赋为空，方便后续children的比对
              if(element.children && element.children.length>0){
                element.children.forEach(child=>{
                  ele.children.forEach(kid=>{
                    if(child.MenuName == kid.name_cn){
                      list[where].children.push(kid);
                    }
                  })
                })
              }
            }
          })
        })
        this.menu_list=[...list];
        this.menu_list[0].selected = true; // 首次加载页面菜单栏默认选择第一项
      }
    })
  }

  private changeMenuStatus(name: string) {
    this.menu_list.forEach((value: any, key: any) => {
      this.menu_list[key].selected = false;
      if (value.name === name) {
        this.menu_list[key].selected = true;
        this.emitActive(this.menu_list[key].name_cn, this.menu_list[key].name, [
          this.menu_list[0],
          this.menu_list[key]
        ]);
      }
      if (
        this.menu_list[key].children &&
        this.menu_list[key].children.length > 0
      ) {
        this.menu_list[key].children.forEach((v: any, k: any) => {
          this.menu_list[key].children[k].selected = false;
          if (v.name === name) {
            this.menu_list[key].children[k].selected = true;
            this.menu_list[key].show = true;
            this.emitActive(
              this.menu_list[key].children[k].name_cn,
              this.menu_list[key].children[k].name,
              [
                this.menu_list[0],
                this.menu_list[key],
                this.menu_list[key].children[k]
              ]
            );
          }
        });
      }
    });
    this.mine_active = name;
    if (this.$router.currentRoute.name !== name)
      this.$router.push({ name: name });
    this.$forceUpdate();
  }

  //跳转页面
  private onClickMenuList(key: number): any {
    if (this.menu_list[key].children && this.menu_list[key].children.length > 0) {
      this.menu_list[key].show = this.menu_list[key].show ? false : true;
      this.$forceUpdate();
    } else {
      if (this.mine_active === this.menu_list[key].name) return false;
      this.mine_active = this.menu_list[key].name;
      this.emitActive(this.menu_list[key].name_cn, this.menu_list[key].name, [
        this.menu_list[0],
        this.menu_list[key]
      ]);
    }
  }

  //子集跳转
  private goChildrenPath(key: number, k: number): any {
    if (this.mine_active === this.menu_list[key].children[k].name) return false;
    this.mine_active = this.menu_list[key].children[k].name;
    this.emitActive(
      this.menu_list[key].children[k].name_cn,
      this.menu_list[key].children[k].name,
      [this.menu_list[0], this.menu_list[key], this.menu_list[key].children[k]]
    );
  }

  public mounted(): void {
    this.menu_list = broken(this.$router.options.routes);
    this.$nextTick(() => {
      let name: string = this.$router.currentRoute.name == undefined ? "" : this.$router.currentRoute.name;
      this.changeMenuStatus(name);
      this.menu_list[0].selected = true;
      this.$forceUpdate();
    });
    this.getMenu();
  }
}
