<template>
  <section>
  <div class="cover" v-if="showCover" @click="hideCover">
    <div class="pass" @click="stop">
      <p>密码修改</p>
      <p><span>原始密码</span><el-input class="input" placeholder="请输入原密码" v-model="oldPass" show-password></el-input></p>
      <p><span>新密码</span><el-input class="input" placeholder="请输入新密码" v-model="newPass" show-password></el-input></p>
      <p><span>确认新密码</span><el-input class="input" placeholder="再次输入新密码" v-model="checkNew" show-password></el-input></p>
      <el-button plain size='mini' @click="confirm">确认</el-button>
      <el-button plain size='mini' @click="cancel">取消</el-button>
    </div>
  </div>
  <div id="header" v-if="isLogin">
    <div class="name">
      <img src="@/static/img/vm-logo.png" alt="">
      <h3>新零售智能管理平台</h3>
    </div>
    <div class="classify">
      <span @click="changeCenter('operate')">运营中心</span>
      <span @click="changeCenter('market')">营销中心</span>
      <span @click="changeCenter('data')">数据中心</span>
    </div>
    <div class="userInfo">
      <p><img src="@/static/img/personal.png" alt=""> <span>{{userInfo.userName}}</span></p>
      <p @click="rePass"><img src="@/static/img/reWritePass.png" alt=""> <span>修改密码</span></p>
      <p @click="logOut"><img src="@/static/img/logOut.png" alt=""> <span>退出</span></p>
    </div>
  </div>
  <el-container id="manage-container" v-if="isLogin && whichCenter=='operate'" >
    <transition name="el-fade-in">
      <el-aside class="nav-container" v-show="true" width="auto">
        <el-scrollbar class="scroll-bar">
          <menu-container :active_name="active_name" @active="handleMenuActive"></menu-container>
        </el-scrollbar>
      </el-aside>
    </transition>
    <el-container>
      <el-header class="header-container">
        <head-container :show="true" :title="title" :crumb="crumb" @active="handleHeadCrumbActive" @logOut='logOut'></head-container>
      </el-header>
      <el-main class="main-container">
        <div id="app">
          <router-view></router-view>
        </div>
      </el-main>
    </el-container>
  </el-container>
  <el-container id="manage-container" v-if="isLogin && whichCenter=='market'" >
    <transition name="el-fade-in">
      <el-aside class="nav-container" v-show="true" width="auto">
        <el-scrollbar class="scroll-bar">
          <menu-container :active_name="active_name" @active="handleMenuActive"></menu-container>
        </el-scrollbar>
      </el-aside>
    </transition>
    <el-container>
      <el-header class="header-container">
        <head-container :show="true" :title="title" :crumb="crumb" @active="handleHeadCrumbActive" @logOut='logOut'></head-container>
      </el-header>
      <el-main class="main-container">
        <div id="app">
          <router-view></router-view>
        </div>
      </el-main>
    </el-container>
  </el-container>
  <el-container id="manage-container" v-if="isLogin && whichCenter=='data'" >
    <transition name="el-fade-in">
      <el-aside class="nav-container" v-show="true" width="auto">
        <el-scrollbar class="scroll-bar">
          <menu-container :active_name="active_name" @active="handleMenuActive"></menu-container>
        </el-scrollbar>
      </el-aside>
    </transition>
    <el-container>
      <el-header class="header-container">
        <head-container :show="true" :title="title" :crumb="crumb" @active="handleHeadCrumbActive" @logOut='logOut'></head-container>
      </el-header>
      <el-main class="main-container">
        <div id="app">
          <router-view></router-view>
        </div>
      </el-main>
    </el-container>
  </el-container>
  <Login v-if="!isLogin" @goHome='goHome'></Login>
  </section>
</template>

<script>
  // @ is an alias to /src
  import MenuContainer from '@/components/MenuContainer';
  import HeadContainer from '@/components/HeadContainer';
  import Login from '@/views/Login.vue';
  import {modefyPassword} from '@/api/public.js'

  export default {
    name: 'App',
    components: {
      MenuContainer, HeadContainer,Login
    },
    data() {
      return {
        showCover:false,
        oldPass:'',
        newPass:'',
        checkNew:'',
        title: '',
        crumb: [],
        active_name: '',
        isLogin:false,
        whichCenter:'operate',
        userInfo:JSON.parse(sessionStorage.getItem('userInfo')) || undefined,
      }
    },
    methods: {
      // 切换中心
      changeCenter(type){
        // this.whichCenter=type;
        // this.$store.commit('menu/changeMenuData',type);
      },
      handleMenuActive(e) {
        e.crumb.shift()
        this.active_name = e.name
        this.title = e.title
        this.crumb = e.crumb
      },
      handleHeadCrumbActive(e) {
        this.active_name = e
      },
      goHome(isLogin){
        this.isLogin = isLogin;
        window.location.reload();
      },
      // 修改密码
      rePass(){
        this.showCover=true;
      },
      stop(e){
        window.event? window.event.cancelBubble = true : e.stopPropagation();
      },
      hideCover(){
        this.showCover=false;
      },
      confirm(){
        let data={data1:this.oldPass,data2:this.newPass};
        if(this.newPass===this.checkNew){
          modefyPassword(data).then(res=>{
            if(res.success){
              this.$message({
                showClose: true,
                message: res.Message,
                type: 'success'
              });
              this.newPass='';
              this.oldPass='';
              this.checkNew='';
              Vue.prototype.$bus.$emit('isLogin', false);
              this.isLogin=false;
              window.location.reload();
            }else{
              this.$message({
                showClose: true,
                message: res.Message,
                type: 'error'
              });
            }
          })
        }else{
          this.$message({
            showClose: true,
            message: '两次输入的密码不一致',
            type: 'error'
          });
        }
      },
      cancel(){
        this.showCover=false;
      },
      // 退出登录
      logOut(){
         this.$confirm('确认要退出登陆吗？')
         .then(()=>{
           sessionStorage.removeItem('userInfo');
           sessionStorage.removeItem('token');
           this.isLogin=false;
           sessionStorage.removeItem('login');
         })
         .catch(()=>{})
      },
      storeage(type){
        if(type===1){
          window.addEventListener("beforeunload", () => {
            sessionStorage.setItem('login',this.isLogin)
          });
        }else{
          window.removeEventListener("beforeunload", () => {});
        }
      }
    },
    mounted(){
      this.$bus.$on('isLogin',(flag)=>{
         if(!flag){
           this.isLogin=flag;
         }
      });
      const res = sessionStorage.getItem('login')?JSON.parse(sessionStorage.getItem('login')):false;
      if(res){
        this.isLogin=res;
        sessionStorage.setItem('login','');
        this.$forceUpdate()
      }
      this.storeage(1);//刷新前保持islogin的状态
    },
    //beforeDestroybeforeDestroy
    destroyed(){
      sessionStorage.setItem('login',this.isLogin);
      this.storeage(2)//组件销毁前移除监听事件
       this.$bus.$off('isLogin')
    }
  }
</script>

<style lang="scss">
  @import "./static/default";
  ::-webkit-scrollbar {/* 滚动条整体样式 */
      height: 8px; /* 宽高分别对应横竖滚动条的尺寸 */
      width: 8px;
      background:#fff;
      border-radius: 2px;
      // -webkit-box-shadow: inset 1px 0 1px rgba(0,0,0,0.2);
  }
  ::-webkit-scrollbar-thumb {/* 滚动条里的小方块 */
      border-radius: 4px;
      background: #c7c9cc;
      height: 120px;
  }
  ::-webkit-scrollbar-track {/* 滚动条里面的轨道 */
      -webkit-box-shadow: inset 0 0 2px #fff;
      border-radius: 1px;
      background:#fff;
  }
  .el-table tr{
    color: #808080 !important;
    font-size: 14px;
  }
  .el-scrollbar{
    padding-bottom: 11px !important;
  }
  .el-select{
    width: 100%;
  }
  .cover{
    height: 100vh;
    width: 100vw;
    position: fixed;
    top:0;
    left: 0;
    background: rgba($color: #000000, $alpha: 0.5);
    z-index: 20001;
    .pass{
      height: 300px;
      width: 500px;
      position: absolute;
      left: 50%;
      top:50%;
      margin-top: -150px;
      margin-left: -250px;
      background: #fff;
      padding: 20px;
      border-radius: 5px;
      button{
        float: right;
        margin-left: 10px;
        margin-top: 10px;
      }
    }
    p{
      height: 40px;
      line-height: 40px;
      margin-bottom: 10px;
      span{
        display: inline-block;
        line-height: 40px;
        width: 100px;
        font-size: 14px;
      }
      .input{
        width: 300px;
        height: 30px;
        margin: 0 auto;
      }
    }
  }
  #header{
    width: 100%;
    box-sizing: border-box;
    height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #203258 ;
    color: #fff;
    font-size: 12px;
    .classify{
      span{
        width: 48px;
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #C8D3D4;
        line-height: 17px;
      }
    }
    .name{
      display: flex;
      align-items: center;
    }
    h3{
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 25px;
    }
    img{
      height: 36px;
      width: 36px;
      border-radius: 50%;
      margin-right: 10px;
    }
    span{
      display: inline-block;
      height: 17px;
      line-height: 17px;
      font-size: 12px;
      margin-left: 30px;
      &:hover{
          cursor: pointer;
        }
    }
    .userInfo{
      display: flex;
      align-items: center;
      img{
        height: 14px;
        width: 14px;
      }
      p{
        margin-left: 30px;
        display: flex;
        align-items: center;
      }
      span{
        margin: 2px;
      }
    }
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    overflow:auto;
    box-sizing: border-box;
    background: #fff;
    border-radius: 5px;
    height: 100%;
  }

  div,p,span,b,em {
    box-sizing: border-box;
    font-size: 14px;
  }
  /deep/.el-drawer.rtl{
    overflow-y: auto !important;
  }
  #manage-container {
    width: 100%;
    height: calc(100vh - 55px);
    background-color: rgba(240, 242, 245, 1);
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    overflow:auto;
  }

  .header-container {
    padding: 0 !important;
    box-sizing: border-box;
  }

  .main-container {
    width:100% ;
    height: 100%;
    padding: 24px !important;
    overflow:auto;
  }

  .nav-container {
    width: 208px !important;
    height: calc(100vh - 55px);
    overflow: hidden !important;
    background-color: $primary-color;
    box-shadow: 3px 0px 8px 0px rgba(0, 21, 41, 0.35);

    .nav-top {
      width: 100%;
      height: 23px;
      margin-bottom: 30px;
      margin-top: 29px;
      display: flex;
      justify-content: center;
      align-items: center;
      .el-image {
        width: 60%;
      }
    }

    .scroll-bar {
      width: 100%;
      overflow-x: hidden;
      height: calc(100vh - 23px - 59px);
    }
  }
  .el-popper ::v-deep .el-scrollbar{
    overflow-y:auto;
    height: 100%;
  }

  .el-scrollbar__wrap {
    overflow-x: hidden !important;
  }

</style>
