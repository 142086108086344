const url = {
    dev: {
        publicUrl: 'http://vmachine.sumszet.com/api/',
        manageUrl: 'http://vmachine.sumszet.com/api/',
    },
    pro: {
        publicUrl: 'http://vmachine.sumszet.com/api/',
        manageUrl: 'http://vmachine.sumszet.com/api/',
    }
}

export const get = () => {
    if(process.env.NODE_ENV === 'production'){
        return url['pro']
    }else{
        return url['dev']
    }
}
