export const operateMenu=[
    {
      path: '/',
      name: 'Home',
      name_cn:'首页',
      icon: 'el-icon-s-marketing',
      component: () => import(/* webpackChunkName: "about" */ '@/views/Home.vue'),
    },
    {
        path: '/equipManage',
        name: 'EquipManage',
        name_cn:'设备管理',
        icon: 'el-icon-setting',
        component: {render: (e) => e("router-view")},
        children:[{
            path: '/equipManage/equipList',
            name: 'EquipList',
            // @ts-ignore
            name_cn:'设备列表',
            component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/equipList/index.vue'),
        },
        {
          path: '/equipManage/abnormalEquipList',
          name: 'AbnormalEquipList',
          // @ts-ignore
          name_cn:'异常设备列表',
          component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/abnormalEquipList/index.vue'),
        },
        {
          path: '/equipManage/replenishmentRecord',
          name: 'ReplenishmentRecord',
          // @ts-ignore
          name_cn:'补货管理',
          component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/replenishmentRecord/index.vue'),
        },
        {
          path: '/equipManage/inventoryRecord',
          name: 'InventoryRecord',
          // @ts-ignore
          name_cn:'盘存记录',
          component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/inventoryRecords/index.vue'),
        }]
    },
    {
      path: '/goods',
      name: 'Goods',
      name_cn:'商品管理',
      icon: 'el-icon-box',
      component: {render: (e) => e("router-view")},
      children:[{
          path: '/goods/goodsList',
          name: 'goodsList',
          // @ts-ignore
          name_cn:'商品列表',
          component: () => import(/* webpackChunkName: "about" */ '@/views/goodsManage/goodsList/index.vue'),
      },
      {
        path: '/goods/goodsTypeList',
        name: 'goodsTypeList',
        // @ts-ignore
        name_cn:'商品类型列表',
        component: () => import(/* webpackChunkName: "about" */ '@/views/goodsManage/goodsTypeList/index.vue'),
      }]
    },
    {
        path: '/trading',
        name: 'Trading',
        name_cn:'交易管理',
        icon: 'el-icon-sell',
        component: {render: (e) => e("router-view")},
        children:[{
            path: '/trading/orderList',
            name: 'OrderList',
            // @ts-ignore
            name_cn:'交易订单',
            component: () => import(/* webpackChunkName: "about" */ '@/views/tradingManage/orderList/index.vue'),
        },{
          path: '/trading/abnormalOrder',
          name: 'AbnormalOrder',
          // @ts-ignore
          name_cn:'异常订单',
          component: () => import(/* webpackChunkName: "about" */ '@/views/tradingManage/abnormalOrder/index.vue'),
        },{
          path: '/trading/subAccount',
          name: 'SubAccount',
          // @ts-ignore
          name_cn:'分账管理',
          component: () => import(/* webpackChunkName: "about" */ '@/views/tradingManage/subAccount/index.vue'),
        },{
          path: '/trading/refund',
          name: 'Refund',
          // @ts-ignore
          name_cn:'退款管理',
          component: () => import(/* webpackChunkName: "about" */ '@/views/tradingManage/refund/index.vue'),
        },{
          path: '/trading/subAccountNum',
          name: 'SubAccountNum',
          // @ts-ignore
          name_cn:'分账账号管理',
          component: () => import(/* webpackChunkName: "about" */ '@/views/tradingManage/subAccountNum/index.vue'),
        },]
    },
    {
      path: '/place',
      name: 'Place',
      name_cn:'场所管理',
      icon: 'el-icon-office-building',
      component: {render: (e) => e("router-view")},
      children:[{
          path: '/place/placeList',
          name: 'PlaceList',
          // @ts-ignore
          name_cn:'场所列表',
          component: () => import(/* webpackChunkName: "about" */ '@/views/placeManage/placeList/index.vue'),
      },
      ]
    },
    {
      path: '/miniUserManage',
      name: 'MiniUserManage',
      name_cn:'小程序用户管理',
      icon: 'el-icon-user',
      component: {render: (e) => e("router-view")},
      children:[{
          path: '/miniUserManage/wechatUserList',
          name: 'WechatUserList',
          // @ts-ignore
          name_cn:'微信用户列表',
          component: () => import(/* webpackChunkName: "about" */ '@/views/miniUserManage/wechatUserList/index.vue'),
      },{
        path: '/miniUserManage/aliUserList',
        name: 'AliUserList',
        // @ts-ignore
        name_cn:'支付宝用户列表',
        component: () => import(/* webpackChunkName: "about" */ '@/views/miniUserManage/aliUserList/index.vue'),
      }]
    },
    {
      path: '/communicationManage',
      name: 'CommunicationManage',
      name_cn:'通讯管理',
      icon: 'el-icon-data-line',
      component: {render: (e) => e("router-view")},
      children:[{
          path: '/communicationManage/communicationRecords',
          name: 'CommunicationRecords',
          // @ts-ignore
          name_cn:'通讯记录',
          component: () => import(/* webpackChunkName: "about" */ '@/views/communicationManage/communicationRecords/index.vue'),
      },
      ]
    },
    {
      path: '/rights',
      name: 'Rights',
      name_cn:'权限管理',
      icon: 'el-icon-lock',
      component: {render: (e) => e("router-view")},
      children:[{
          path: '/rights/menu',
          name: 'Menu',
          // @ts-ignore
          name_cn:'菜单管理',
          component: () => import(/* webpackChunkName: "about" */ '@/views/rights/menu/index.vue'),
        },{
          path: '/rights/user',
          name: 'rightsUser',
          // @ts-ignore
          name_cn:'用户管理',
          component: () => import(/* webpackChunkName: "about" */ '@/views/rights/user/index.vue'),
        },{
          path: '/rights/rightsManege',
          name: 'RightsManege',
          // @ts-ignore
          name_cn:'权限分配',
          component: () => import(/* webpackChunkName: "about" */ '@/views/rights/rightsManege/index.vue'),
        },{
          path: '/rights/roleManege',
          name: 'RoleManege',
          // @ts-ignore
          name_cn:'角色管理',
          component: () => import(/* webpackChunkName: "about" */ '@/views/rights/roles/index.vue'),
        }]
    },
    {
      path: '/storage',
      name: 'Storage',
      name_cn:'仓储管理',
      icon: 'el-icon-receiving',
      component: {render: (e) => e("router-view")},
      children:[{
          path: '/storage/inStorage',
          name: 'inStorage',
          // @ts-ignore
          name_cn:'入库管理',
          component: () => import(/* webpackChunkName: "about" */ '@/views/storage/inStorage/index.vue'),
        },{
          path: '/storage/outStorage',
          name: 'outStorage',
          // @ts-ignore
          name_cn:'出库管理',
          component: () => import(/* webpackChunkName: "about" */ '@/views/storage/outStorage/index.vue'),
        },{
          path: '/storage/supplier',
          name: 'supplier',
          // @ts-ignore
          name_cn:'供应商管理',
          component: () => import(/* webpackChunkName: "about" */ '@/views/storage/supplier/index.vue'),
        },{
          path: '/storage/customer',
          name: 'customer',
          // @ts-ignore
          name_cn:'客户管理',
          component: () => import(/* webpackChunkName: "about" */ '@/views/storage/customer/index.vue'),
        },{
          path: '/storage/warehouse',
          name: 'warehouse',
          // @ts-ignore
          name_cn:'仓库管理',
          component: () => import(/* webpackChunkName: "about" */ '@/views/storage/warehouse/index.vue'),
        },{
          path: '/storage/storageLocation',
          name: 'storageLocation',
          // @ts-ignore
          name_cn:'库位管理',
          component: () => import(/* webpackChunkName: "about" */ '@/views/storage/storageLocation/index.vue'),
        },{
          path: '/storage/storageRecord',
          name: 'storageRecord',
          // @ts-ignore
          name_cn:'库存记录',
          component: () => import(/* webpackChunkName: "about" */ '@/views/storage/storageRecord/index.vue'),
        },{
          path: '/storage/inventory',
          name: 'inventory',
          // @ts-ignore
          name_cn:'盘存管理',
          component: () => import(/* webpackChunkName: "about" */ '@/views/storage/inventory/index.vue'),
        },]
    }, 
];