export const marketMenu=[
    {
        path: '/',
        name: 'Data',
        name_cn:'数据中心',
        icon: 'el-icon-document',
        component: {render: (e) => e("router-view")},
        children:[{
            path: '/data/orderData',
            name: 'OrderData',
            // @ts-ignore
            name_cn:'订单数据',
            component: () => import(/* webpackChunkName: "about" */ '@/views/data/data.vue'),
        },]
    },
];