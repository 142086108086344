import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import './theme/element/index.css'
import axios from 'axios'
import echarts from 'echarts'
import '@/assets/iconfont/iconfont.css'
import vueQr from 'vue-qr'

// import VideoPlayer from 'vue-video-player'
// import 'vue-video-player/src/custom-theme.css'
// import 'video.js/dist/video-js.css'
// Vue.use(VideoPlayer)

Vue.use(Element);

Vue.config.productionTip = false;
Vue.use(Element);
Vue.prototype.$echarts = echarts;
Vue.prototype.$bus = new Vue();
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
